import App from "@core/App";
import ChatPreview from "@core/ChatPreview";
import { CompanyAvatar } from "@core/components/Avatars";
import { useApi } from "@core/contexts/ApiContext";
import { useConfig } from "@core/contexts/ConfigContext";
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { Transition } from "react-transition-group";

import useIOS from "../hooks/useIOS";
import useMediaQuery from "../hooks/useMediaQuery";
import useMobile from "../hooks/useMobile";

const triggerText = "Chat and apply here!";

const mobileBreakpoint = "640px";

const supportsDvw = CSS.supports("width: 100dvw");
const supportsDvh = CSS.supports("height: 100dvh");

/**
 * @param {object=} options
 * @param {number=} options.zIndex
 * @param {number=} options.zIndexTrigger
 * @param {string=} options.themeColor
 * @param {number=} options.launcherMarginX
 * @param {number=} options.launcherMarginY
 */
const getAnimatedStyles = ({
  zIndex,
  zIndexTrigger,
  themeColor,
  launcherMarginX,
  launcherMarginY,
} = {}) => {
  const transparentThemColor = themeColor
    ? `${themeColor}CC`
    : "rgba(0, 0, 0, 0.3)";

  const borderColor = themeColor ? `${themeColor}99` : "rgba(0, 0, 0, 0.2)";
  const marginX = `${launcherMarginX ?? 20}px`;
  const marginY = `${launcherMarginY ?? 20}px`;
  const margin = `${marginY} ${marginX}`;
  return {
    drawer: {
      margin: "0px",
      width: "350px",
      height: supportsDvh ? "100dvh" : "100vh",
      borderRadius: "0px",
      zIndex: zIndex ?? 10_000,
    },
    overlay: {
      margin: "0px",
      width: supportsDvw ? "100dvw" : "100vw",
      height: supportsDvh ? "100dvh" : "100vh",
      borderRadius: "0px",
      zIndex: zIndex ?? 10_000,
    },
    preview: {
      width: "350px",
      height: "550px",
      margin: margin,
      zIndex: zIndexTrigger ?? zIndex ?? 10_000,
    },
    trigger: {
      margin: margin,
      width: "300px",
      height: "50px",
      borderRadius: "12px",
      zIndex: zIndexTrigger ?? zIndex ?? 10_000,
      border: `1px solid ${borderColor}`,
      boxShadow: `0 4px 10px -2px ${transparentThemColor}, 0 2px 8px -1px ${transparentThemColor}`,
    },
    triggerMobile: {
      margin: "20px",
      width: "calc(100vw - 40px)",
      height: "50px",
      borderRadius: "12px",
      zIndex: zIndexTrigger ?? zIndex ?? 10_000,
      border: `1px solid ${borderColor}`,
      boxShadow: `0 4px 10px -2px ${transparentThemColor}, 0 2px 8px -1px ${transparentThemColor}`,
    },
  };
};

const useAnimatedStyles = (isOpen, isPreviewOpen, opts) => {
  const isMobileWidth = useMediaQuery(
    `(max-width: ${parseInt(mobileBreakpoint)}px)`,
  );

  const animatedStyles = getAnimatedStyles(opts);

  if (isPreviewOpen) {
    return {
      styles: animatedStyles.preview,
      isFullscreen: false,
    };
  }

  if (!isOpen) {
    return {
      styles: isMobileWidth
        ? animatedStyles.triggerMobile
        : animatedStyles.trigger,
      isFullscreen: false,
    };
  }

  if (isMobileWidth) {
    return { styles: animatedStyles.overlay, isFullscreen: true };
  }

  return { styles: animatedStyles.drawer, isFullscreen: false };
};

const getFixedStyles = (position, marginBottom, marginX) => {
  switch (position) {
    case "left":
      return {
        left: 0,
        marginBottom: `${parseInt(marginBottom)}px`,
        marginLeft: `${parseInt(marginX)}px`,
      };
    case "right":
      return {
        right: 0,
        marginBottom: `${parseInt(marginBottom)}px`,
        marginRight: `${parseInt(marginX)}px`,
      };
    default:
      return {
        left: "50%",
        transform: "translateX(-50%)",
        marginBottom: `${parseInt(marginBottom)}px`,
      };
  }
};

export default function Embedded() {
  const isMobileWidth = useMediaQuery(
    `(max-width: ${parseInt(mobileBreakpoint)}px)`,
  );
  const { session, fetchSession, api } = useApi();

  const { config } = useConfig();

  const chatPreviewStorageKey = `apply:${api.token || "unknown"}:chatPreview`;

  // Change to the correct property coming from config
  const launcherCollapsed = config?.launcher_collapsed;

  const getReducedPreviewClosed = () => {
    try {
      return localStorage.getItem(chatPreviewStorageKey) === "closed";
    } catch (err) {
      return false;
    }
  };

  const setReducedPreviewClosed = () => {
    try {
      localStorage.setItem(chatPreviewStorageKey, "closed");
    } catch (err) {
      console.error("Error setting reduced preview closed", err);
    }
  };

  const dialogRef = useRef(null);
  const buttonRef = useRef(null);
  const chatButtonRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(
    () => !isMobileWidth && !getReducedPreviewClosed(),
  );

  useEffect(() => {
    if ((isOpen || isPreviewOpen) && !session) {
      fetchSession();
    }
  }, [isOpen, session, fetchSession, isPreviewOpen]);

  useEffect(() => {
    if (isOpen) {
      dialogRef.current?.show();
    } else {
      dialogRef.current?.close();
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen && isMobileWidth && isPreviewOpen) {
      setIsPreviewOpen(false);
    }
  }, [isMobileWidth, isPreviewOpen, isOpen]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsPreviewOpen(false);
    setIsOpen(true);
  };

  const handleCloseReduced = () => {
    // Save it in local storage
    setReducedPreviewClosed();
    setIsPreviewOpen(false);
  };

  const { styles, isFullscreen } = useAnimatedStyles(
    isOpen,
    isPreviewOpen && !launcherCollapsed,
    {
      zIndex: config?.z_index,
      zIndexTrigger: config?.z_index_trigger,
      themeColor: config?.theme_color,
      launcherMarginX: config?.launcher_padding_side,
      launcherMarginY: config?.launcher_padding_bottom,
      launcherPosition: config?.launcher_position,
    },
  );

  const isDrawer = isOpen && !isFullscreen;
  const location =
    config?.launcher_position === "LAUNCHER_POSITION_BOTTOM_LEFT"
      ? "left"
      : "right";
  const fixedStyles = getFixedStyles(
    location,
    config?.launcher_padding_bottom ?? 20,
    config?.launcher_padding_side ?? 20,
  );

  const iOS = useIOS();
  const isMobile = useMobile();

  useEffect(() => {
    const targetElement = dialogRef.current;

    // Body scroll locking breaks in-dialog scrolling on iOS.
    if (isOpen && isMobile && !iOS) {
      disableBodyScroll(targetElement);
    } else {
      enableBodyScroll(targetElement);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [isOpen, isMobile, iOS]);

  return (
    <div
      className={clsx(
        "fixed bottom-0 text-sm text-black bg-white rounded-xl border shadow-md motion-reduce:transition-none transition-[width,height] duration-300",
        isOpen && "overflow-clip",
      )}
      style={{
        ...styles,
        ...fixedStyles,
      }}
    >
      <dialog
        ref={dialogRef}
        className="absolute top-0 left-0 flex-col text-gray-900 bg-gray-50 open:flex"
        style={{
          width: styles.width,
          height: styles.height,
        }}
      >
        <App close onClose={handleClose} isOpen={isOpen} isDrawer={isDrawer} />
      </dialog>

      <Transition
        nodeRef={chatButtonRef}
        mountOnEnter
        unmountOnExit
        appear
        exit={false}
        in={isPreviewOpen && !isOpen && !launcherCollapsed}
        timeout={1000}
      >
        {(state) => (
          <button
            onClick={handleOpen}
            className={clsx(
              "rounded-xl flex w-full h-full gap-2 transition-opacity duration-300 focus:outline-none focus:ring-2 focus:ring-primary/40 text-left",
              {
                "opacity-100": state === "entering" || state === "entered",
                "opacity-0": state === "exited" || state === "exiting",
              },
            )}
            ref={chatButtonRef}
          >
            <ChatPreview
              close
              onClose={handleCloseReduced}
              isOpen={isOpen}
              isDrawer={isDrawer}
            />
          </button>
        )}
      </Transition>

      <Transition
        nodeRef={buttonRef}
        mountOnEnter
        unmountOnExit
        appear
        exit={false}
        in={!isOpen}
        timeout={1000}
      >
        {(state) => (
          <button
            onClick={handleOpen}
            className={clsx(
              "items-center rounded-xl justify-center flex flex-row w-full h-full gap-2 p-2 transition-opacity duration-300 focus:outline-none focus:ring-2 focus:ring-primary/40",
              {
                "opacity-100": state === "entering" || state === "entered",
                "opacity-0": state === "exited" || state === "exiting",
              },
            )}
            ref={buttonRef}
          >
            <span className="text-gray-700 text-md">
              {config.launcher_text}
            </span>
            <CompanyAvatar config={config} />
          </button>
        )}
      </Transition>
    </div>
  );
}
